<template>
  <div class="page-catalog__filter" :class="{ 'is-show': isShowFilter }">
    <div class="page-catalog__search">
      <input class="page-catalog__filter-title" placeholder="Быстрый поиск" />
      <img src="@/assets/img/icons/search.svg" alt="" />
    </div>

    <div class="page-catalog__filter-wrapper">
      <div
        v-for="filter in filters"
        :key="filter.id"
        class="page-catalog__category"
      >
        <div
          class="page-catalog__category-name"
          @click="toggleFilterList(filter)"
          :class="{ 'is-active': filter.isOpen }"
        >
          {{ $t(filter.label) }}
        </div>
        <div
          v-if="filter.data.length > 0"
          class="page-catalog__list"
          :class="{ 'is-active': filter.isOpen }"
        >
          <div
            v-for="(filterItem, index) in filter.data"
            :key="index"
            class="page-catalog__list-item"
          >
            <input
              type="checkbox"
              :id="getUniqId(filterItem)"
              :checked="isChecked(filter, filterItem)"
              @change="selectFilter(filter.type, filterItem)"
            />
            <label class="filter-label" :for="getUniqId(filterItem)">
              {{ getLabel(filterItem) }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="page-catalog__filter-btns">
      <button class="catalog-filter-show" @click.prevent="showFilteredProducts">
        {{ $t("showProducts") }}
      </button>
      <button class="catalog-filter-reset" @click="clearFilters">
        {{ $t("resetFilters") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store/modules/catalog";

import { getAllCategories } from "@/api/category";
import { getShopTypes } from "@/api/shop";
import { getAllDiscounts } from "@/api/discount";

export default {
  name: "DiscountFilters",
  props: {
    isShowFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: [
        {
          id: 1,
          label: "category",
          data: [],
          isOpen: true,
          type: "categories",
        },
        {
          id: 2,
          label: "shops",
          data: [],
          isOpen: true,
          type: "shopTypes",
        },
        {
          id: 3,
          label: "discountType",
          data: [],
          isOpen: true,
          type: "discountTypes",
        },
      ],
    };
  },
  computed: {
    ...mapState("catalogModule", { selectedFilters: "filters" }),
  },

  watch: {
    selectedFilters: {
      deep: true,
      handler() {
        if (
          !this.selectedFilters.categories?.length &&
          !this.selectedFilters.shopTypes?.length &&
          !this.selectedFilters.discountTypes?.length
        ) {
          this.$router.push({
            query: { city_id: this.$route.query.city_id },
          });
        }
      },
    },
  },
  created() {
    if (this.$route.query.filters) {
      this.replaceFilters(JSON.parse(this.$route.query.filters));
      this.$emit("load-products-by-filter");
    }
    getAllCategories().then((categories) => {
      for (let i = 1; i < categories.length + 1; i++) {
        if (categories[i]) {
          this.filters[0].data.push(
            categories.find((e) => e.attributes.order === i)
          );
        }
      }
      this.getCategories(this.filters[0].data);
    });
    getShopTypes().then((shopTypes) => {
      this.filters[1].data = shopTypes;
    });
    getAllDiscounts().then((discounts) => {
      for (let i = 1; i < discounts.length + 1; i++) {
        if (discounts[i]) {
          this.filters[2].data.push(
            discounts.find((e) => e.attributes.order === i)
          );
        }
      }
      console.log("filters", this.filters[2].data);
    });
  },
  methods: {
    ...mapActions("catalogModule", {
      changeFilter: actionTypes.changeFilter,
      replaceFilters: actionTypes.replaceFilters,
      clearFilters: actionTypes.clearFilters,
      getCategories: actionTypes.getCategories,
    }),
    isChecked(filter, filterItem) {
      return this.selectedFilters[filter?.type].includes(filterItem?.id);
    },
    getLabel(filterItem) {
      return filterItem?.attributes?.label
        ? filterItem?.attributes?.label
        : filterItem?.attributes?.name;
    },
    getUniqId(filterItem) {
      return filterItem?.attributes?.label
        ? filterItem?.id + filterItem?.attributes?.label
        : filterItem?.id + filterItem?.attributes?.name;
    },

    showFilteredProducts() {
      this.$router.replace({
        path: `${this.$route.path}#catalogHash`,
        query: this.$route.query,
      });
      this.$emit("load-products-by-filter");
      this.$emit("close-filter");
    },

    selectFilter(filterType, filterItem) {
      this.changeFilter({ type: filterType, filterId: filterItem?.id });
      this.$router.push({
        query: {
          ...this.$route.query,
          filters: JSON.stringify(this.selectedFilters),
        },
        replace: true,
      });
    },

    toggleFilterList(filter) {
      const foundFilter = this.filters.find((f) => f?.id === filter?.id);
      if (foundFilter) {
        foundFilter.isOpen = !foundFilter.isOpen;
      }
    },
  },
};
</script>
