import axios from "@/plugins/axios";
import qs from "qs";

export const getAllDiscounts = async () => {
  const query = qs.stringify(
    {
      populate: {
        localizations: "*",
      },
    },
    { encodeValuesOnly: true }
  );
  const response = await axios.get(`/discounts?${query}`);
  return response?.data?.data;
};

export const getAllProductsByDiscountId = async (id, pagination) => {
  const query = qs.stringify(
    {
      pagination: {
        page: pagination?.page || 1,
        pageSize: pagination?.pageSize || 5,
      },
      populate: {
        products: { populate: { image: "*", localizations: "*" } },
        localizations: "*",
      },
    },
    { encodeValuesOnly: true }
  );
  const response = await axios.get(`/discounts/${id}?${query}`);
  return response?.data?.data;
};
