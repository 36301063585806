import axios from "@/plugins/axios";
import qs from "qs";

export const getAllCategories = async () => {
  const query = qs.stringify(
    {
      populate: {
        localizations: "*",
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/categories?${query}`);
  return response?.data?.data;
};

export const getCategoriesByIds = async (ids) => {
  const query = qs.stringify(
    {
      populate: {
        products: {
          populate: "*",
        },
      },
      filters: {
        id: {
          $in: ids,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/categories?${query}`);
  return response?.data?.data;
};
